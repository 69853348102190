import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
	difference: {
		display: "flex",
		textAlign: 'center',
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down('sm')]: {
			margin: "auto",
			maxWidth: theme.spacing(44),
		}
	},
	differenceCircle: {
		width: theme.spacing(22),
		height: theme.spacing(22),
		border: "10px solid #f9f9f9",
		padding: theme.spacing(4),
		borderRadius: "50%",
		marginBottom: theme.spacing(2),
		backgroundColor: theme.palette.common.white,
		[theme.breakpoints.down('sm')]: {
			width: theme.spacing(17),
			height: theme.spacing(17),
			padding: theme.spacing(2),
		}
	},
	diffrenceDescription: {
		color: theme.palette.text.secondary
	},
}))

const Differences = () => {
  const classes = useStyles()

  const { datoCmsStandOutSection = {} } = useStaticQuery(graphql`
    query QuerysDifferences {
      datoCmsStandOutSection {
        standOutTitle
        standOutSection {
          id
          saddleDifferenceTitle
          saddleDifferenceDescription
          saddleDifferenceImage {
            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
          }
        }
      }
    }
  `)

  const { standOutTitle, standOutSection = [] } = datoCmsStandOutSection

  return (
    <Box py={10}>
      <Typography variant="h4" align="center" gutterBottom>{standOutTitle}</Typography>
      <Grid container>
        {standOutSection.map(({ id, saddleDifferenceTitle, saddleDifferenceDescription, saddleDifferenceImage }) => (
          <Grid item md={3} sm={6} xs={12} key={id}>
            <Box p={4} className={classes.difference}>
              <div className={classes.differenceCircle}>
                {saddleDifferenceImage && <GatsbyImage image={getImage(saddleDifferenceImage)} alt={saddleDifferenceTitle} />}
              </div>
              <Typography gutterBottom>
                <strong>{saddleDifferenceTitle}</strong>
              </Typography>
              <Typography className={classes.diffrenceDescription}>
                {saddleDifferenceDescription}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Differences
