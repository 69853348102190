import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const useStyles = makeStyles(theme => ({
	root: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    overflow: 'hidden',
    overflowX: "auto",
    scrollSnapType: "mandatory",
    scrollSnapType: "x mandatory",
  },
  item: {
    height: "auto",
    flex: `0 0 30%`,
    scrollSnapAlign: "start",
    textAlign: 'center',
    backgroundColor: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flex: `0 0 60%`,
    }
  },
  link: {
    height: '100%',
    display: "flex",
    flexFlow: "column nowrap",
    textDecoration: 'none',
  },
  caption: {
    marginTop: 'auto'
  }
}))

const SaddlesCarousel = ({ saddleType }) => {
	const classes = useStyles()

  const { allDatoCmsPageSaddle = {} } = useStaticQuery(graphql`
    query QuerysSaddlesCarousel {
      allDatoCmsPageSaddle(sort: {fields: position, order: ASC}) {
        nodes {
          id
          slug
          saddleName
          saddleImage {
            gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED)
          }
          saddleType
        }
      }
    }
  `)

  const saddles = allDatoCmsPageSaddle && allDatoCmsPageSaddle.nodes || []
  const filteredByType = saddles.filter(saddle => saddle.saddleType === saddleType)

	return (
    <Box className={classes.root} mb={10}>
      {filteredByType.map(saddle => (
        <Box p={2} key={saddle.id} className={classes.item}>
          <Link to={`/siodla/${saddleType}/${saddle.slug}/`} className={classes.link}>
            <GatsbyImage image={getImage(saddle.saddleImage)} alt={saddle.saddleName} />
            <Typography variant="caption" color="textSecondary" className={classes.caption}>{saddle.saddleName}</Typography>
          </Link>
        </Box>
      ))}
    </Box>
	)
}

export default SaddlesCarousel;