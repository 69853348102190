import React from 'react';
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography"
import MuiDialogContent from '@material-ui/core/DialogContent';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { makeStyles, withStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const DialogContent = withStyles({
  root: {
    padding: 0,
    "&:first-child": {
      padding: 0,
    }
  }
})(MuiDialogContent)

const useStyles = makeStyles(theme => ({
  features: {
		height: "100%",
		display: "flex",
		textAlign: 'center',
		alignItems: "center",
		flexDirection: "column",
	},
  featureGridItem: {
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(4)
		}
	},
  featuresCircle: {
		display: 'inline-block',
		width: theme.spacing(10),
		height: theme.spacing(10),
		borderRadius: "50%",
		backgroundColor: theme.palette.common.white,
	},
	featuresFeature: {
		color: theme.palette.text.primary,
		fontSize: "22px",
		marginTop: theme.spacing(3),
		lineHeight: 1.3,
	},
	featuresDescription: {
		color: theme.palette.text.grey,
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
  featuresImage: {
		marginTop: 'auto',
		cursor: 'pointer',
	},
  dialog: {
    position: 'relative',
    "& .MuiPaper-root": {
      overflow: "visible",
    }
  },
  dialogContent: {
    position: 'relative'
  },
  iconPrev: {
    top: "50%",
    left: "-100px",
    color: theme.palette.common.white,
    position: 'absolute',
    transform: "translateY(-50%)",
    transition: "background-color 150ms",
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.grey[400],
      "&:hover": {
        backgroundColor: theme.palette.grey[600],
      },
    },
    [theme.breakpoints.down('md')]: {
      left: 20,
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    }
  },
  iconNext: {
    top: "50%",
    right: "-100px",
    color: theme.palette.common.white,
    position: 'absolute',
    transform: "translateY(-50%)",
    transition: "background-color 150ms",
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.grey[400],
      "&:hover": {
        backgroundColor: theme.palette.grey[600],
      },
    },
    [theme.breakpoints.down('md')]: {
      right: 20,
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    }
  }
}))

const Features = ({ saddles }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
  }

  const handlePrev = () => {
    if (currentIndex <= 0) {
      setCurrentIndex(saddles.length - 1)
      return
    }
    setCurrentIndex(state => state - 1)
  }

  const handleNext = () => {
    if (currentIndex >= saddles.length - 1) {
      setCurrentIndex(0)
      return
    }
    setCurrentIndex(state => state + 1)
  }


  const handleClickOpen = (index) => {
    setCurrentIndex(index)
    setOpen(true)
  };

  return (
    <>
      <Grid container spacing={3}>
        {saddles.map(({ id, saddleDescription, saddleFeature, saddleImage }, index) => (
          <Grid item sm={4} xs={12} key={id} className={classes.featureGridItem}>
            <Box maxWidth={290} margin="auto" className={classes.features}>
              <div className={classes.featuresCircle} />
              <Typography variant="caption" className={classes.featuresFeature}>
                <strong>{saddleFeature}</strong>
              </Typography>
              <Typography className={classes.featuresDescription}>{saddleDescription}</Typography>
              {saddleImage && (
                <GatsbyImage
                  alt={saddleFeature}
                  image={getImage(saddleImage)}
                  className={classes.featuresImage}
                  onClick={() => handleClickOpen(index)}
                />
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="feature-dialog-title"
        aria-describedby="feature-dialog-description"
        disableEscapeKeyDown
        disableRestoreFocus
        className={classes.dialog}
      >
        <DialogContent className={classes.dialogContent}>
          <GatsbyImage
            alt={saddles[currentIndex].saddleFeature}
            image={getImage(saddles[currentIndex].saddleImage)}
          />
        </DialogContent>
        <IconButton onClick={handlePrev} className={classes.iconPrev} size="large">
          <KeyboardArrowLeftIcon fontSize='large' />
        </IconButton>
        <IconButton onClick={handleNext} className={classes.iconNext} size="large">
          <KeyboardArrowRightIcon fontSize='large' />
        </IconButton>
      </Dialog>
    </>
  );
}

Features.defaultProps = {
  saddles: []
}

export default Features