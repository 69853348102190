import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { makeStyles } from "@material-ui/core/styles"
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
	item: {
    padding: theme.spacing(1),
    "&:nth-child(odd)": {
      backgroundColor: theme.palette.secondary.main
    }
  }
}))

const SpecificationDetails = ({ data }) => {
	const classes = useStyles()

	const specification = JSON.parse(data)

	return (
    <Box>
      {Object.keys(specification).map((item, i) => {
        if (!specification || !specification[item]) return
        return (
          <Typography component="div" variant="body2" key={i} className={classes.item}>
            <strong dangerouslySetInnerHTML={{ __html: item}} />
            <span>{` - `}</span>
            <span dangerouslySetInnerHTML={{ __html: specification[item] }} />
          </Typography>
        )
      })}
    </Box>
	)
}

export default SpecificationDetails;