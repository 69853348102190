import React from "react"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Hero from "../components/hero"
import Title from "../components/title"
import Button from "../components/button"
// import Seo from "../components/seo"
import Layout from "../components/layout"
import TeaserBox from '../components/teaserBox'
import Features from '../components/features'
import Differences from "../components/differences"
import SpecificationDetails from '../components/singleSaddle/SpecificationDetails'
import SaddlesCarousel from '../components/singleSaddle/SaddlesCarousel'

const useStyles = makeStyles(theme => ({
	title: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down('sm')]: {
			alignItems: "flex-start",
		}
	},
	secondName: {
		color: theme.palette.text.grey,
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(1)
		}
	},
	teaser: {
		backgroundColor: theme.palette.secondary.main
	},
	specification: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10)
	},
	specificationImage: {
		display: 'flex',
		alignItems: "center",
		justifyContent: "center",
	},
	specificationsTitle: {
		fontWeight: 'bold',
		fontSize: '1.6rem',
		marginBottom: theme.spacing(3)
	},
	specificationCustomization: {
		fontWeight: 'bold'
	},
	specificationOptions: {
		display: "flex",
		marginTop: theme.spacing(2),
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down('sm')]: {
			flexFlow: "column nowrap",
			marginTop: theme.spacing(4),
		}
	},
	specificationButton: {
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(1.5)
		}
	},
	restTitle: {
		fontSize: '1.6rem',
		textAlign: 'center',
		marginBottom: theme.spacing(3)
	}
}))

const SingleSaddleTemplate = ({ data }) => {
	const classes = useStyles()
	const {
		saddleType,
		saddleName,
		saddleImage,
		heroTitle,
		heroImage,
		saddleSecondName,
		featuresOfSaddles = [],
		teaserTitle,
		teaserDescription,
		saddleRestTitle,
		specificationData,
		specificationsTitle,
		specificationButtonText,
		specificationCustomization
	} = data && data.datoCmsPageSaddle || {}

	return (
		<Layout>
			<Hero image={heroImage} title={heroTitle} />
			<Container maxWidth="lg">
				<Box py={6} className={classes.title}>
					<Title disableAfterElement={saddleName && saddleName[0]?.includes('J')}>{saddleName}</Title>
					<Typography className={classes.secondName}>{saddleSecondName}</Typography>
				</Box>

				<Features saddles={featuresOfSaddles} />

				<Differences />

				{teaserTitle && (
					<TeaserBox teaserTitle={teaserTitle} teaserDescription={teaserDescription} />
				)}

				<Grid container className={classes.specification}>
					<Grid item md={6} className={classes.specificationImage}>
						<GatsbyImage alt={saddleName} image={getImage(saddleImage)} />
					</Grid>
					<Grid item md={6}>
						<Typography className={classes.specificationsTitle}>{specificationsTitle}</Typography>
						{specificationData
							&& !!specificationData.length
							&& <SpecificationDetails data={specificationData} />
						}

						<Box className={classes.specificationOptions}>
							{specificationCustomization && (
								<Typography
									className={classes.specificationCustomization}
								>Możliwość personalizacji</Typography>
							)}
							<Link to="/saddlefitting/testy">
								<Button bgColor="#000" className={classes.specificationButton}>{specificationButtonText}</Button>
							</Link>
						</Box>
					</Grid>
				</Grid>

				<Typography
					variant="caption"
					component="div"
					className={classes.restTitle}
				>{saddleRestTitle}</Typography>
				<SaddlesCarousel saddleType={saddleType} />
			</Container>
		</Layout>
	)
}

export const query = graphql`
  query querySingleSaddle($id: String!) {
    datoCmsPageSaddle(id: { eq: $id }) {
      id
      slug
      saddleName
      specificationData
			saddleImage {
     		gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
      }
      saddleType
      specificationButtonText
      specificationCustomization
      specificationEight
      specificationFive
      specificationFour
			specificationFourDressage
      specificationNine
      specificationOne
      specificationSeven
      specificationSix
      specificationThree
      specificationTwo
      specificationsTitle
      saddleSecondName
      saddleRestTitle
      heroTitle
      heroImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      featuresOfSaddles {
        id
        saddleFeature
        saddleDescription
        saddleImage {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      teaserTitle
      teaserDescription
    }
  }
`

export default SingleSaddleTemplate
